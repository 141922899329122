.contributorIteamEditOption ul li,
.contributorIteamEditOption ul li a {
    font-weight: 500 !important;
    font: normal normal 400 .75rem / 1 Fira Sans !important;
    color: #8A1A75 !important;
    cursor: pointer;
}

.btnDeleteContributor {
    margin-left: 9%,
}

.contributorOption {
    // display: block;
    position: absolute;
    background-color: rgb(202, 92, 175);
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;
    padding: 0;
    z-index: 1;
    border: 1px solid #FEC1EB;
    // border-radius: 0px 4px 4px;
    text-transform: uppercase;
    font-size: 0.75rem;
    width: 230px;
    right: 0px;
    margin-top: 10px;
}
.contributorOption div {
    border-bottom: 1px solid #FEC1EB;
    font-weight: 400;
    line-height: 1.2;
    padding: 12px 20px;
    white-space: normal;
    cursor: pointer;
    background:#fdf5fb;
}
.contributorOption div + div {
    background-color: #FEE9F7;
}
.contributorOption div:hover {
    background-color: #FEE9F7;
    border-bottom: 1px solid transparent;
    color: #8A1A75;
}
li.flexdownarrow {
    padding-left: 10px;
    padding-right: 10px;
}
@media only screen and (max-width: 840px) {
    .contributorIteam {
        // flex-wrap: wrap;
        // flex-direction: column;
        // align-items: flex-start;
    }

    ul.contributorIteamEditOption {
        padding-left: 10px;
    }

    .flexdownarrow {
        display: flex;
        gap: 5px;
    }
}

@media only screen and (max-width: 620px) {
    .contributorIteam {
        // flex-wrap: wrap;
        // flex-direction: column;
        // align-items: flex-start;
    }

    // ul.contributorIteamEditOption {
    //     padding-left: 10px;
    // }
    // .flexdownarrow {
    //     display: flex;
    // gap: 5px;
    // }

    .contributorIteamQ span {
        width: 35px !important;
        text-align: center;
    }

    .contributorIteamQ {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }

    .contributorIteam {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding-left: 20px;
        padding-top: 10px;
    }

    .contributorIteamEditOption ul {
        position: relative;
        left: 13px;
    }
}

@media only screen and (max-width: 513px) {
    .btnDeleteContributor {
        margin-left: 0;
        margin-top: 10px;
    }
}