.footer-wrapper .mb-2 {
    font: normal normal 600 1.0rem / 1.4 Martel;
}
.footerLogoRight {
    text-align: right;
}


@media only screen and (max-width: 1024px) {
    .footerLogoRight {
        text-align: right;
        max-width: 180px;
    }
    .mb-2.footerLogoRight {
        margin-top: 20px;
    }

}
@media only screen and (max-width: 767px) {
.footer-wrapper .mb-2 {
    font: normal normal 600 1.0rem / 1.4 Martel;
    margin-top: 15px;
}
.page-wrapper.horizontal-wrapper .footer {
    padding: 50px 0 0;
}
.footerLogoRight {
    text-align: left;
}
}