div#sidebar-menu .left-section.col-md-7 {
    text-align: right;
}

button.headerMyAccountBtn {
    position: relative;
    top: 6px;
    background: rgb(233, 212, 228);
    border: 2px solid rgb(237, 211, 229);
    border-radius: 30px;
    padding: 0.3rem 0.6rem;
    font-size: 16px;
    transition: all 250ms linear;
}

.headerMyAccountBtn {
    &:hover {
        background: rgb(255, 255, 255);
        border: 2px solid #8A1A75;
    }
}

a.custom-logout {
    font-weight: 500;
    letter-spacing: 0;
}

ul.sidebar-links.custom-scrollbar {
    overflow: hidden;
}

.menuToggleMenu {
    display: none;
}

.menuToggleMenu img {
    max-width: 30px;
}

.toggle-sidebar {
    display: none !important;
}
ul.mobile-section{
    display: none;
}

@media only screen and (max-width: 991px) {
    .menuToggleMenu {
        display: block;
    }

    .left-header h6,
    .left-header .h6 {
        width: auto;
    }

    .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
        width: 25%;
    }

    .page-wrapper .page-header .header-wrapper {
        margin: 0;
        padding: 0;
    }

    .page-wrapper .page-header .header-wrapper .nav-right {
        width: 30%;
    }

    ul.mainMenu {
        display: none;
    }   
    ul.mobile-section {
        display: block;
        display: flex;
        flex-direction: column;
    }

}

@media only screen and (max-width: 767px) {
    ul.sidebar-links.custom-scrollbar .left-section.col-md-7 {
        width: 50%;
    }

    ul.sidebar-links.custom-scrollbar .right-section.col-md-5 {
        width: 50%;
    }

    .page-wrapper.horizontal-wrapper .header-logo-wrapper {
        width: 200px;
    }

    .logo-wrapper {
        margin: 0 10px 0 0 !important;
    }

    .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
        width: 30%;
        display: inline-block;
    }
}

@media only screen and (max-width: 575px) {
    .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
        width: 100%;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        position: absolute;
        right: 15px;
        top: 40px;
    }
    .page-wrapper .page-header .header-wrapper .nav-right > ul li .profile-media img {
        width: 15px;
    }
    .page-wrapper .page-header .header-wrapper .nav-right {
        width: 50%;
    }
    .page-wrapper .page-header .header-wrapper {
        padding: 0px !important;
        margin-bottom: 6px !important;
    }
    .notification-slider.overflow-hidden h6.mb-0.f-w-600 {
        top: -12px;
        position: relative;
        left: 20px;
    }
}
@media only screen and (max-width: 375px) {
    .page-wrapper .page-header .header-wrapper .nav-right {
        width: 100%;
        position: relative;
        top: inherit;
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: 140px;
        text-align: center;
        padding-bottom: 20px !important;
    }
    .page-wrapper .page-header .header-wrapper {
        justify-content: center;
    }
    .notification-slider.overflow-hidden h6.mb-0.f-w-600 {
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}