

.change_book_design .row h3 {
    font-size: 1.15rem;
    margin-bottom: 0;
}
.change_book_design {
  padding: 60px 0;
}
.center-with-line {
  text-align: center;
}

h1, h2, h3, h4, h5 {
    color: #000;
}

h3 {
  font: normal normal 300 1.6rem / 1.4 Martel;
}

.tns-outer {
    padding: 0 !important;
}

.tns-outer {
    margin-top: -265px;
}

.tns-outer h3{
  margin-top: 12rem;
  font-size: 1.15rem;
  margin-bottom: 2rem;
  font: normal normal 300 1.6rem / 1.4 Martel;
  font-size: 1.15rem;
}


*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


h2 {
  font: normal normal 300 2rem / 1.4 Martel;
}


.change_book_design .row h4 {
  color: #8a1975;
  font: normal normal 300 15px / 24px Fira Sans;
  margin-bottom: 12px;
}


.tns-controls, button[data-action="start"], button[data-action="stop"] {
    display: none;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer;
}
.tns-visually-hidden {
    position: absolute;
    left: -10000em;
}
#tns1 {
    font-size: 0px;
}

.tns-horizontal.tns-subpixel {
    white-space: nowrap;
}
.tns-gallery {
    position: relative;
    left: 0;
    min-height: 1px;
}
.tns-slider {
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    transition: all 0s;
}

#tns1 > .tns-item {
    width: calc(100%);
    transition-duration: 0.3s;
    animation-duration: 0.3s;
}
.tns-item h4{
  color: #8a1975;
  font: normal normal 300 15px / 24px Fira Sans;
  margin-bottom: 2rem;
}
#tns1 > .tns-item {
    font-size: 17.6px;
}
.tns-horizontal.tns-subpixel>.tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}
.tns-gallery>.tns-slide-active {
    position: relative;
    left: auto !important;
}
.tns-gallery>.tns-item {
    position: absolute;
    left: -120%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
}
.tns-slider>.tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.tns-fadeIn {
    opacity: 1;
    filter: alpha(opacity = 100);
    z-index: 0;
}



.gys-active img.cover_img:hover {
    box-shadow: 0px 0px 6px 2px #fad7ef;
    border-radius: 3px;
}


img.cover_img {
    padding: 9px 8px 0px;
    margin-bottom: 10px;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.tns-nav button.tns-nav-active:after {
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 100%;
  content: "";
  height: 31px;
  position: absolute;
  left: -4px;
  top: -4px;
  width: 31px;
}

.tns-nav button {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 100%;
    display: inline-flex;
    height: 25px;
    margin: 5px;
    position: relative;
    width: 25px;
}

.center-with-line:after, .has-text-align-center.underline:after, .text-center .underline:after, .details-book h2:after {
  background-color: #FEC1EB;
  content: '';
  display: block;
  height: 2px;
  margin: 30px auto;
  width: 102px;
}



