.billingMyStoryTitle {
    padding-top: 30px !important;
}
@media only screen and (max-width: 767px) {
    .myAccountMenu {
        padding: 15px !important;
        align-items: flex-start;
        align-content: center;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 575px) {
    
}