.editBoxDesign {
    width: 60%;
    max-width: 1140px;
    margin: 1px auto;
    border: solid 0px #8a1a75;
    padding: 0px;
    // box-shadow: 0px 0px 25px 2px #ddd;
}
@media only screen and (max-width: 600px) {
    .editBoxDesign {
        width: 100%;
        max-width: 1140px;
        margin: 30px auto 0 auto;
        border: solid 3px #8a1a75;
        padding: 20px 20px 60px 20px;
        box-shadow: none;
    }
}
.edit-title-action {
    position: absolute;
    left: 80%;
}