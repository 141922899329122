.story-settings {
  background: #EEE;
  padding: 1.5%;
  float: left;
  display: flex;
  margin-left: 15%;
}

.my-book-details {
  display: flex;
  flex: 1
}

.my-book-actions {
  display: flex;
  flex-direction: column;
}

.myStoryDesc {
  padding-bottom: 20px;
}

.link-style {
  color: #8A1A75;
  text-decoration: none;
}

.link-style:hover,
.link-style:active {
  color: black;
}



.story-settings {
  margin-left: auto !important;
}

@media only screen and (max-width: 1024px) {
  .story-settings {
    margin-left: auto !important;
  }

}

@media screen and (min-width: 992px) {
  .story-setting-container {
    max-width: 950px;
    margin: 0 auto;
  }

}

@media screen and (max-width: 576px) {
  .my-book-details {
    align-items: center;
    flex-direction: column;
    padding-bottom: 0;
  }

  .my-book-title {
    width: 100% !important;
    padding: 20px 30px 15px !important;
    background-color: #e8d4e4;
    text-align: center;
  }

  .my-book-actions {
    align-items: center;
  }

  .story-settings {
    flex-direction: column;
  }

  .story-settings.story-setting-container {
    padding: 20px 0 0 0;
  }
}