.question-list {
    .list-group-item {
      background: #F7EBF3;
      margin-bottom: 10px;
      border: 0;
      border-radius: 9px;
      padding: 10px 1.12rem 30px;
      position: relative;
      font-weight: 400;
      font-size: 17.6px;
    }
  }
  
  .listing-status {
    background: #8A1A75;
    margin: -10px 1rem -30px calc(-1rem - 4px);
    border-radius: 10px 0 0 10px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 17.6px;
  }
  
  .list-group-item {
    .listing-title {
      padding-right: 20px;
      line-height: 1.2;
    }
  }
  
  small {
    font: normal normal 300 .875em / 1.2 Fira Sans;
  }
  
  small.q_status {
    background: #fff;
    padding: 0 6px;
    color: grey;
    font-size: 11px;
    border-radius: 6px;
  }
  
  .listing-actions {
    span {
      color: #FEC1EB;
    }
  }
  
  span.action_btns {
    position: absolute;
    bottom: 5px;
    left: 40px;
    width: auto;
    z-index: 99;
  }
  
  .listing-grab {
    background-color: #EDD3E5;
    background-image: url('../../images/Icon-move-white.svg');
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
    margin: -10px calc(-1rem - 2px) -34px 1rem;
    border-radius: 0 10px 10px 0;
    width: 60px;
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 100%;
  }
  
  a.inline-button {
    &.viewq {
      padding: 0 10px;
    }
  }
  
  .inline-button {
    font: normal normal 400 .75rem / 1 Fira Sans;
    text-transform: uppercase;
    letter-spacing: .08rem;
    color: #8A1A75;
    border: 0px;
    background: none;
    font-weight: 600;
    padding: 0 10px;
  }
  .viewQuestionData.row {
    max-width: 950px;
    margin: 0 auto;
}
.list-group-item .listing-title small {
  font-weight: 400;
}
.storyBox{
  background: #EEE;
  display: flex;
  cursor: pointer;
  padding: 15px 25px;
  transition: all 250ms linear;

}
.storyBox:hover{
  background-color: #F4E4EF;
  transition: all 250ms linear;
}
@media screen and (max-width: 767px) {
.list-group-item .listing-title small.q_status {
  display: inline-block;
}
}
  @media screen and (max-width: 425px) {
    .question-list .list-group-item { flex-direction: column;}
    .list-group-item .listing-title small { display: inline-block; font-size: .75rem; margin-top: 3px; padding-right: 20px; } 
  }
  @media screen and (max-width: 375px) {
    .list-group-item .listing-status { margin-right: 0.75rem; }
    .list-group-item span.action_btns a { font-size: .75rem;  padding: 1px 0; }
  }
  @media screen and (max-width: 320px) {
    .list-group-item .listing-title { padding-right: 40px; font-size: 1rem; }
    .list-group-item span.action_btns a { font-size: .6rem; }
  }
 


  .preview-title::before {
    content: " ";
    display: block;
    position: absolute;
    width: calc(100% - 4px); /* Border offset from width */
    height: calc(100% - 4px); /* Border offset from height */
    border: 1px solid #000; /* The border you want */
    left: 2px; /* Offset from the left edge */
    top: 2px; /* Offset from the top edge */
  }
  
