/**=====================
     32. pagination CSS Start
==========================**/
/*test Nav bar color */
$page-name: theme, primary, secondary, success, danger, info, dark, warning;
$page-color: var(--theme-default), var(--theme-default), $secondary-color, $success-color, $danger-color, $info-color, $dark-color, $warning-color;
@each $var in $page-name {
  $i: index($page-name, $var);
  .pagination-#{$var} {
    .page-item {
      .page-link {
        color: nth($page-color, $i) !important;
        @media only screen and (max-width: 575px) {
          font-size: 13px;
        }
      }
      &.active {
        .page-link {
          color: $white !important;
          background-color: nth($page-color, $i) !important;
          border-color: nth($page-color, $i);
        }
      }
    }
  }
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-size: large !important;
}
// .wrapped-generated{
//   p{
//     text-align: Justify;
//     font-size: 13px;
//     font-family: "Martel Light";
//     page-break-inside: avoid;
    
//   }
//   .ql-align-center{
//     text-align: Justify;
//     img{
//       display: block;
//       margin: 0 auto;
//       max-width: 100%; /* Ensure the image fits within the container */
//       height: auto;
//       width: 50%;
//       page-break-inside: avoid; /* Avoid splitting images across pages */
//       page-break-before: always; /* Force the image to the next page if it doesn't fit */
//     }
//   }
// }

/* Wrapped generated class */
.wrapped-generated {
  /* Paragraph styling */
  p {
    text-align: justify;
    font-size: 13px;
    font-family: "Martel Light";
    page-break-inside: avoid; /* Prevent paragraph split between pages */
    margin: 10px 20px 37px;
  }

  /* Styling for center-aligned content */
  .ql-align-center {
    text-align: justify; /* Apply text justification inside ql-align-center */

    /* Image inside center-aligned text */
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%; /* Ensure the image fits within the container */
      height: auto;
      width: 50%; /* Adjust the width to 50% */
      page-break-inside: avoid; /* Prevent image from splitting across pages */
      page-break-before: auto; /* Force image to next page if it doesn't fit */
      page-break-after: auto;
      margin-bottom: 20px 10px 40px;
    }
  }
  
}

/* General paragraph styling */
p {
  font-family: 'Martel Light';
  font-size: 13px;
  color: #000; /* Default color for text */
  line-height: 1.5; /* Proper spacing between lines */
  page-break-inside: avoid; /* Prevent splitting paragraphs across pages */
  margin-top: 0;
  margin: 10px 10px 37px;
}

/* Ensure no empty <br /> tags create unwanted space */
p:empty {
  display: none;
}




/* Centering paragraphs that have the class "ql-align-center" */
.ql-align-center {
  text-align: justify;
}

/* Style for bold text */
p strong {
  font-weight: bold;
}

// img, p {
//   page-break-inside: avoid;
//   margin-bottom: 37px; /* Adjust as per your requirement */
// }

// img {
//   page-break-before: always;
// }
/* Prevent splitting image and paragraph content */
// p + img,
// img + p {
//   page-break-before: always;  
//   margin-bottom: 120px; /* Add space between image and paragraph */
// }


p br + br {
  display: none; /* Hide consecutive <br /> tags */
}


/* For any aligned paragraphs */
p.ql-align-center {
  text-align: justify;
  font-size: 13px;
}


.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/**=====================
     32. pagination CSS Ends
==========================**/
