.billingMyStoryTitle {
    padding-top: 30px !important;
}
@media only screen and (max-width: 767px) {
    p.billingIteamListingTitle {
        font-size: 1.5rem !important;
    }
    .billingMyStoryGrid .col-10 {
        width: 65%;
    }

    .billingMyStoryGrid .col-2 {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 35%;
    }
    .billingMyStoryGrid.row {
        padding: 0;
    }
    .mobileCenterGiftedTo .col-10 {
        text-align: center;
    }
    .myAccountMenu {
        padding: 15px !important;
        align-items: flex-start;
        align-content: center;
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 575px) {
    .billingMyStoryGrid.row {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }
    .billingMyStoryGrid .col-10 {
        width: 100%;
        padding: 20px 30px !important;
    }
    .billingMyStoryGrid .col-2 {
        width: 100%;
        padding: 20px !important;
    }
    .mobileCenterGiftedTo .col-10 {
        text-align: center;
    }
}


/* Styling for the Quill toolbar buttons */
.ql-toolbar button {
  position: relative;
}

/* Tooltip styling */
.ql-toolbar button[data-tooltip]:hover::after {
  content: attr(data-tooltip); /* Get the text from the data-tooltip attribute */
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for tooltip */
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out;
}

/* Add a small arrow below the tooltip */
.ql-toolbar button[data-tooltip]:hover::before {
  content: '';
  position: absolute;
  bottom: 90%; /* Position just above the button */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent; /* Create arrow with the same color */
  z-index: 10;
}

/* Hide tooltip by default */
.ql-toolbar button[data-tooltip]::after,
.ql-toolbar button[data-tooltip]::before {
  opacity: 0;
  visibility: hidden;
}
