.dashicon-arrow::before {
    font-family: "dashicons";
    font-size: 17px;
    content: "\f345";
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: -400px;
    max-width: 400px;
    height: 100%;
    background: #fff;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    padding: 55px 0 0 20px;
    transition: left 0.3s ease;
    z-index: 1000;
}
.sidebarInner {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 50px;
}

.sidebar.open {
    left: 0;
}

.sidebar h2 {
    font: normal normal 300 1.6rem / 1.4 Martel;
    color: #000;
    /* font-weight: 700; */
    margin-bottom: .5rem;
    margin-top: 0; 
}

.sidebar ol {
    list-style: none;
    padding-left: 25px;
    display: block;
    
}

.sidebar ol li {
    padding: 10px 10px;
    cursor: pointer;
    font: normal normal 500 1.1rem / 1.5 Fira Sans;
    font-size: 16px;
    color: #8A1A75;
    border-Bottom: 1px solid #ccc;
    list-style: decimal;
    
}

.sidebar ol li:hover {
    color: #000;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    z-index: 999;
}

.sidebar-overlay.open {
    opacity: 1;
    visibility: visible;
}

.invite-form {
    padding: 1rem !important;
    background-color: #eee;
    /* width: 100%;  */
    text-align: left;
    margin-top: .5rem !important;
}

.invite-form h4 {
    color:#000;
    font: normal normal 300 1.25rem / 1.4 Martel;
}

.invite-form p {
    color: #404040;
    font: normal normal 300 1.1rem / 1.5 Fira Sans;
}

.invite-form form div {
    margin-bottom: 10px;
    font-size: 0.95rem;
    font: normal normal normal 0.9rem / 1.2 Martel;
}

.invite-form form label {
    display: block;
    margin-bottom: 0;
}

.invite-form form input,
.invite-form form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #767676;
    
}

.invite-form form button {
    background-color: #8A1A75;
    border: 2px solid #8A1A75; 
    color: #fff; 
    align-items: center;
    border-radius: 30px; 
    display: inline-flex;
    font: normal normal 300 15px / 17px Fira Sans;
    justify-content: center;
    letter-spacing: 0.15rem; 
    min-height: 45px; 
    padding: 0 20px; 
    text-transform: uppercase; 
}

/* .invite-form form button:hover {
    background-color: #721a5e;
} */

.invite-sent-message {
    padding: 1rem !important;
    background-color: #eee;
    /* width: 100%;  */
    text-align: left;
    margin-top: .5rem !important;
}

.invite-sent-message h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font: normal normal 300 1.25rem / 1.4 Martel
}


.model-invite-form {
    padding: 1rem !important;
    /* background-color: #eee; */
    /* width: 100%;  */
    text-align: left;
    margin-top: .5rem !important;
}

.model-invite-form h4 {
    color:#000;
    font: normal normal 300 1.25rem / 1.4 Martel;
}

.model-invite-form p {
    color: #404040;
    font: normal normal 300 1.1rem / 1.5 Fira Sans;
}

.model-invite-form form div {
    margin-bottom: 20px;
    padding: 0 !important;
}
.model-invite-form form div path {
    fill: #00000073;
}
.model-invite-form form label {
    display: inline-block;
    margin-bottom: 8px;
    margin-top: 8px;
    font-weight: 700;
    padding: 0;
}

.model-invite-form form input,
.model-invite-form form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #767676;
    
}

.model-invite-form form button {
    background-color: #8A1A75;
    border: 2px solid #8A1A75; 
    color: #fff; 
    align-items: center;
    border-radius: 30px; 
    display: inline-flex;
    font: normal normal 300 15px / 17px Fira Sans;
    justify-content: center;
    letter-spacing: 0.15rem; 
    min-height: 45px; 
    padding: 0 20px; 
    text-transform: uppercase; 
}


.model-invite-sent-message {
    padding: 1rem !important;
    /* background-color: #eee; */
    /* width: 100%;  */
    text-align: left;
    margin-top: .5rem !important;
}

.model-invite-sent-message h3 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    font: normal normal 300 1.25rem / 1.4 Martel
}
.sidebar ol li::marker {
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
    left: 10px;
    
}

@media only screen and (max-width: 767px) {
.sidebar {
    padding: 20px;
}
.sidebar ol li::marker {
    padding-left: 10px;
    margin-left: 10px;
    left: 10px;
    position: relative;
}
}
