.password-input-container {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px; /* Adjust this value to control the distance from the right side */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  