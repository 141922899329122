
.custom-button {
    background: #8A1A75;
    letter-spacing: 0.15rem; 
    border: 2px solid #8A1A75; 
    font-weight: 300; 
    color: #FFF; 
    border-radius: 30px; 
    padding: 0.5rem 1.5rem 0.4rem;
    text-transform: uppercase; 
    min-height: 45px; 
    font-family: 'Fira Sans';
    font-size: .95rem;
    transition: all 250ms linear;    
    display: flex;
    align-items: center;
  
    &:hover {
      background-color: #fff;
      color: #000;
      font-weight: 400;
    }
    span {
      position: relative;
      top:2px;
    }
}
button.custom-button:hover span {
  color: #FEC1EB;
}
.delete-button {
  background: #a5013b;
  letter-spacing: 0.15rem; 
  border: 2px solid #a5013b; 
  font-weight: 300; 
  color: #fff; 
  border-radius: 30px; 
  padding: 0 20px; 
  text-transform: uppercase; 
  min-height: 45px; 
  font-family: 'Fira Sans';
  font-size: 0.95rem;
  transition: all 250ms linear;
  &:hover {
    background: #fff;
    color: #8A1A75;
  }
}

.white-button {
  background: #fff ;
  letter-spacing: 0.15rem; 
  border: 2px solid #8A1A75; 
  font-weight: 400; 
  color: #8A1A75; 
  border-radius: 50px; 
  padding: 0.5rem 1rem; 
  text-transform: uppercase; 
  /* min-height: 45px;  */
  font-family: 'Fira Sans';
  font-size: .9rem;
  transition: all 350ms linear;
  &:hover {
    background:#8A1A75;
    color: #fff;
  }
}

